<template>
	<div>
		<s-crud
			title="Lugares de muestra"
			:config="config"
			add
			edit
			remove
			@save="save($event)"
			:filter="filter"
		>
			<template scope="props">
				<v-container>
					<v-row>
						<v-col cols="12" lg="6" md="6" sm="12">
							<s-text
								v-model="props.item.SplName"
								label="Descripción"
							></s-text>
						</v-col>
						<v-col cols="12" lg="6" md="6" sm="12">
							<s-select-definition
								v-model="props.item.SplStatus"
								:def="1332"
								label="Estado"
							></s-select-definition>
						</v-col>
					</v-row>
				</v-container>
			</template>

			<template v-slot:SplStatus="{row}">
				<v-chip 
					x-small
					:color="row.SplStatusColor"
				>
					{{row.SplStatusName}}
				</v-chip>
			</template>
		</s-crud>
	</div>
</template>

<script>
	import _sSamplingLocationService from "@/services/FrozenProduction/SamplingLocationService.js";
	export default {
		props: {
			TypeCultive: {
				type: Number,
				default: 0
			},
		},

		watch: {
			TypeCultive() {
				this.Initialize();
			}
		},
		
		data() {
			return {
				filter: {TypeCultive: 0},
				config: {
					service: _sSamplingLocationService,
					model: {
						SplID: "ID",
						SplStatus: "number"
					},
					headers: [
						{ text: "ID", value: "SplID" },
						{ text: "SplName", value: "SplName" },
						{ text: "Estado", value: "SplStatus"},
					],
				},
			}
		},

		methods: {
			Initialize()
			{
				this.filter = { TypeCultive: this.TypeCultive }
			},

			save(item){
				if(item.SplName == ""){
					this.$fun.alert("Ingrese descripción");
					return;
				}
				item.TypeCultive = this.TypeCultive;
				item.save();
			}
		},

		created () {
			
		},
	}

</script>
