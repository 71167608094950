<template>
	<div>
		<v-row>
			<v-col cols="12" lg="6" md="6" sm="12">
				<maintainer-crop
					@rowSelected="rowSelected($event)"
				></maintainer-crop>
			</v-col>
			<v-col cols="12" lg="6" md="6" sm="12">
				<sampling-location
					:TypeCultive="TypeCultive"
				></sampling-location>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import MaintainerCrop from '../../TechnicalAssistance/MaintainerCropArticleSap/MaintainerCrop.vue'
	import SamplingLocation from "./SamplingLocation";

	export default {
		components: {
			MaintainerCrop,
			SamplingLocation
		},

		data() {
			return {
				TypeCultive: 0
			}
		},

		methods: {
			rowSelected(rows) {
				if(rows.length > 0){
					this.TypeCultive = rows[0].DedValue;
				}
			}
		},
	}
</script>